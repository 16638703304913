<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="系统私信" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
    </div>
    <div class="content">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div @click="showMessage(v)" class="listItem" v-for="(v, key) in notice" :key="key">
          <div class="listTitle">{{ v.title }}</div>
          <div class="listContent html">
            <p>{{ v.message }}<br /></p>
          </div>
          <div class="listTime">
            <div class="listTimeText">{{ v.create_time }}</div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <van-dialog v-model="show" width="400" :title="messageitem.title" show-cancel-button>
      <div class="message-html">
        {{ messageitem.message }}
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      notice: {},
      loading: false,
      finished: false,
      show: false,
      messageitem: {}
    }
  },
  methods: {
    back() {
      return window.history.back()
    },
    showMessage(msg) {
      this.messageitem = msg
      this.show = true
      this.message_read()
    },
    getNoticeList() {
      this.$http({
        method: 'get',
        url: 'message_list'
      }).then((res) => {
        console.log(res)
        this.notice = res.data.data
      })
    },
    message_read() {
      this.$http({
        method: 'get',
        data: { message_id: this.messageitem.id },
        url: 'message_read'
      }).then((res) => {
        console.log(res)
        this.notice = res.data.data
      })
    },

    onRefresh() {
      setTimeout(() => {
        this.$toast('刷新成功')
        this.isLoading = false
        this.getNoticeList()
      }, 500)
    }
  },
  created() {
    this.getNoticeList()
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.message-html {
  width: 100%;
  margin: 20px;
  font-size: 30px;
  padding: 20px;
}

::v-deep .van-loading__text {
  color: #200808;
  font-size: 35px;
}
.container .content {
  height: calc(100% - 20px);
  overflow: auto;
}
.container .content .listItem {
  margin-bottom: 20px;
  padding: 20px 20px 0;
  position: relative;
  color: #000;
  background-color: #fff;
}
.container .content .listItem .listTitle {
  font-size: 38px;
}
.container .content .listItem .listContent {
  border-bottom: 2px solid #f2f2f5;
  padding: 5px 0;
  font-size: 25px;
}
.container .content .listItem .listTime {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
}
.container .content .listItem .listTime .listTimeText {
  color: #656566;
  font-size: 30px;
}
</style>
