<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="援助交际" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
      <div class="info">
        <p class="value">账号：{{ userinfo.username }}</p>
        <p class="value">积分：{{ userinfo.money }}</p>
      </div>
      <div class="info">
        <p class="value">
          我的邀请码:<span style="font-size: 40px; font-weight: 600">{{ userinfo.code }}</span>
        </p>
        <p class="value"></p>
      </div>
      <div class="content">
        <van-list>
          <div class="menu-item" v-for="item in menulist" :key="item.id" @click="$router.push({ path: item.url })">
            <span class="menu-item-label">{{ item.name }}</span>
            <van-icon class="icon" name="arrow" />
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      win_money: 0,
      personalreport: {},
      userinfo: {},
      menulist: [
        // { name: '代理说明', id: 100, url: '/task' },
        { name: '客户管理', id: 101, url: '/AgentUserManager' },
        { name: '开户中心', id: 110, url: '/AddMember' },
        { name: '下级认证记录', id: 123, url: '/AgentBetRecord' },
        { name: '下级兑换记录', id: 124, url: '/AgentWithdraw' },
        { name: '下级积分记录', id: 125, url: '/AgentRecharge' }
      ],
      token: ''
    }
  },

  methods: {
    back() {
      return window.history.back()
    },
    coptcode() {},
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then((res) => {
        if (res.code === 200) {
          this.userinfo = res.data
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    },
    getPersonalreport() {
      this.$http({
        method: 'get',
        url: 'user_get_personalreport'
      }).then((res) => {
        if (res.code === 200) {
          this.personalreport = res.data
          this.win_money = this.personalreport.win_money - this.personalreport.play_money
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  background-color: #ffffff;
  padding-right: 20px;
}
.menu-item .menu-item-label {
  font-size: 24px;
  color: #000;
  font-weight: 500;
  flex: 1;
}
.menu-item .menu-item-icon {
  margin: 25px;
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}

.container .header {
  background: linear-gradient(270deg, #ff678e, #f6ce6b);
}
.container .header .info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: auto;
}
.container .header .info .title {
  font-size: 25px;
  color: #e5e5e5;
}
.container .header .info .value {
  margin: 10px auto;
  color: #fff;
  font-size: 30px;
}
.container .header .info .tip {
  font-size: 30px;
  color: #e5e5e5;
}
.container .content {
  flex: 1;
  background: #f2f2f5;
}
.container .content .datalist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.container .content .datalist .datalistitem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 33.3%;
}
.container .content .datalist .datalistitem .datalistitemValue {
  color: #ff253f;
  font-size: 35px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.container .content .datalist .datalistitem .datalistitemKey {
  color: #979799;
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
