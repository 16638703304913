<template>
  <div class="container page">
    <van-nav-bar title="填写收款卡" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">请输入您的收款卡信息</div>
      <van-cell-group>
        <van-field v-model="bank" label="银行名称" readonly placeholder="请选择银行" @click="showSelectBanks()" />

        <van-field v-model="branch" label="支行名称" type="text" placeholder="请输入支行名称" />
        <van-field v-model="name" v-if="showname" label="真实姓名" type="text" placeholder="请输入真实姓名" />
        <van-field v-model="bankid" label="银行卡号" type="digit" placeholder="请输入真实银行卡号" />
        <van-field v-model="paypassword" v-if="showpass" label="交易密码" type="digit" placeholder="请输入交易密码(6位数字)" />
      </van-cell-group>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">确认绑卡</van-button>
    <van-popup v-model="showBank" position="bottom" round :style="{ height: '45%' }">
      <van-picker show-toolbar :columns="banks" @confirm="onConfirm" @cancel="onCancel" />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank: false,
      userInfo: {},
      bankid: '',
      ubankid: 0,
      bank: '',
      branch: '',
      name: '',
      paypassword: '',
      showpass: true,
      showname: false
    }
  },
  methods: {
    back() {
      return window.history.back()
    },
    bindCard() {
      if (this.userInfo.bankid) {
        this.$toast('请输入银行卡号！')
        return true
      }
      if (this.bank === '' || this.bank === null || this.bank === undefined) {
        this.$toast.fail('请选择银行！')
        return false
      }
      this.$http({
        method: 'post',
        data: { bankid: this.bankid, ubankid: this.ubankid, bank: this.bank, branch: this.branch, name: this.name, paypassword: this.paypassword },
        url: 'user_set_bank'
      }).then((res) => {
        if (res.code === 200) {
          this.$router.push({ path: '/Mine' })
          this.$toast(res.msg)
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    },
    showSelectBanks() {
      this.showBank = true
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data
          this.name = res.data.name
          if (res.data.paypassword) {
            this.showpass = false
          } else {
            this.showpass = true
          }
          if (res.data.name) {
            this.showname = false
          } else {
            this.showname = true
          }
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    },
    getBankList() {
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then((res) => {
        if (res.code === 200) {
          this.banks = res.data
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false
    },
    onCancel() {
      this.showBank = false
    },
    getUserBankInfo() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then((res) => {
        if (res.code === 200) {
          if (res.data.is_bank) {
            this.is_bind = true
            this.ubankid = res.data.info.id
            this.bankid = res.data.info.bankid
            this.branch = res.data.info.branch
            this.bank = res.data.info.bankinfo
          } else {
            this.is_bind = false
          }
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo()
      this.getBankList()
      this.getUserBankInfo()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox {
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title {
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box {
  background: #fff;
}
.main-box .label {
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel,
.van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p {
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(270deg, #ff678e, #f6ce6b);
}
</style>
