<template>
  <div class="container page">
    <van-nav-bar title="收款卡信息" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="wrapper">
      <div class="add-card" @click="toBindCard()" v-if="!is_bind">
        <van-icon name="plus" />
        <span>添加收款卡</span>
      </div>
      <div class="bank" v-else>
        <div class="info">
          <div class="row-content-btn"><span @click="editbank()" class="btn1">编辑</span> <span @click="show = true" class="btn1">解绑</span></div>
          <div class="row-content">银行名称：{{ this.bankInfo.bankinfo }}</div>
          <div class="row-content">真实姓名：{{ this.userInfo.name }}</div>
          <div class="row-content">银行支行：{{ this.bankInfo.branch }}</div>
          <div class="row-content">银行卡号：{{ this.bankInfo.bankid }}</div>
        </div>
      </div>
      <div class="tips">提示:请填写真实银行卡，否则会提现失败！</div>
    </div>

    <van-dialog v-model="show" @confirm="unbindbank()" title="解绑" show-cancel-button>
      <div class="dialog_content">您确定要解绑银行卡吗？</div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_bind: false,
      show: false,
      bankInfo: {},
      userInfo: {}
    }
  },
  methods: {
    back() {
      return window.history.back()
    },
    getUserBankInfo() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then((res) => {
        if (res.code === 200) {
          if (res.data.is_bank) {
            this.is_bind = true
            this.bankInfo = res.data.info
          } else {
            this.is_bind = false
          }
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data
          this.name = res.data.name
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    },
    unbindbank() {
      this.$toast('解绑成功！')
      this.$http({
        method: 'post',
        url: 'user_delete_bank'
      }).then((res) => {
        if (res.code === 200) {
          this.$toast('解绑成功！')
          window.history.back()
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    },
    editbank() {
      this.$router.push({ path: '/BindCard' })
    },
    toBindCard() {
      if (!this.userInfo.name) {
        this.$router.push('Setname')
        this.$toast('请设置姓名后再绑定银行卡！')
        return true
      } else if (!this.userInfo.paypassword) {
        this.$router.push('SetPayPassword')
        this.$toast('请设置提现密码后再绑定银行卡！')
        return true
      } else {
        this.$router.push({ path: '/BindCard' })
      }
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo()
      this.getUserBankInfo()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.dialog_content {
  width: 100%;
  text-align: center;
}

.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.manage-card .wrapper {
  height: calc(100% - 10px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.wrapper .add-card {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
  height: 250px;
}
.wrapper .add-card span {
  margin-left: 10px;
  font-size: 30px;
}
.wrapper .tips {
  margin: 15px 15px;
  font-size: 25px;
  color: #979799;
}

.bank {
  width: 92%;
  margin: 0.1rem auto 0;
  background: linear-gradient(180deg, #f3b15c, #bb9c50);
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 0.2rem;
  margin: 4%;
  color: #fff;
}

.row-content-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn1 {
  width: 80px;
  height: 60px;
  line-height: 80px;
  font-size: 30px;
}

.wrapper .bank .info {
  margin-left: 20px;
  flex: 1;
}
.wrapper .bank .info .row-content {
  margin: 30px 0;
  line-height: 30px;
  font-size: 30px;
}
</style>
