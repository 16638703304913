<template>
  <div class="container page">
    <van-nav-bar title="投注详情" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="main">
      <div style="display: flex; justify-content: center; flex-direction: column; align-items: center; margin-top: 20px">
        <van-image width="100" height="100" :src="$apiurl + gamedata.ico"></van-image>
        <div class="info-item-name">{{ gamedata.name }}</div>
        <div class="info-item-expect">
          第<span class="expect-info">{{ gamedata.expect }}</span
          >期
        </div>
        <div class="info-item-profit">{{ gamedata.is_win == 1 ? '+' + gamedata.profit : '-' + gamedata.profit }}</div>
        <div class="info-item">下单数据：{{ gamedata.type }}</div>
        <div class="info-item">下单时间：{{ gamedata.btime }}</div>
        <div class="info-item">下单金额：{{ gamedata.money }}</div>
        <div class="info-item">用户账户：{{ gamedata.username }}</div>
        <div class="info-item">用户余额：{{ gamedata.umoney }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money: '',
      userInfo: {},
      gamedata: {},
      is_bind: false,
      bankInfo: {},
      gameid: this.$route.query.id
    }
  },
  methods: {
    back() {
      return window.history.back()
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data
          this.name = res.data.name
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    },
    getUserWithdrawRole() {
      this.$http({
        method: 'get',
        data: { gameid: this.gameid },
        url: 'agent_game_info'
      }).then((res) => {
        if (res.code === 200) {
          this.gamedata = res.data
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    },

    withdrawInfo() {
      this.showPopover = true
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo()
      this.getUserWithdrawRole()
      console.log(this.$route.query.id)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';
.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container .main {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: calc(100% - 50px);
  position: relative;
}

.info-item-name {
  font-size: 40px;
  margin-top: 30px;
  font-weight: 400;
}
.info-item {
  font-size: 30px;
  width: 600px;
  margin-top: 20px;
}

.info-item-profit {
  font-size: 40px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}
.expect-info {
  font-size: 30px;
  margin-left: 10px;
  margin-right: 10px;
  color: red;
}
</style>
