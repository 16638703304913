<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar" title="选妃" />
    <div class="convention-item">
      <van-tabs v-model="tabindex" animated sticky line-width="100px" :swipeable="true">
        <van-tab name="city" title="城市大全">
          <div class="card">注：成功办理入会的用户联系我司专属经纪人完成平台三大认证即可成功开启约啪之旅。 。</div>
          <div class="address">
            <van-grid :border="false">
              <van-grid-item @click="addgo(val)" :key="key" v-for="(val, key) in datalist">
                <div style="font-weight: bold">{{ val.city }}</div>
              </van-grid-item>
              <van-grid-item @click="tabindex = 'price'">
                <div style="font-weight: bold; color: #f26998; text-decoration: underline">解锁更多</div>
              </van-grid-item>
            </van-grid>
          </div>
        </van-tab>
        <van-tab name="price" title="流程价格">
          <div class="card">注：成功办理入会的用户联系我司专属经纪人完成平台三大认证即可成功开启约啪之旅。 。</div>
          <div class="rig-box">
            <p class="rig-title">有哪些资源?</p>
            <p class="rig-content">网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到</p>
            <p class="rig-title">服务范围?</p>
            <p class="rig-content">同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。</p>
            <p class="rig-content">注：入会成功联系经纪人完成我司三大认证即可成功开启约啪。</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabindex: 'city',
      datalist: [
        {
          city: '上海',
          list: [
            {
              name: '深圳172 G 卡戴珊型性感身材 5',
              src: '/image/1-201115211639434.jpg'
            },
            {
              name: '上海 166E杯 蜜桃臀A4腰 5',
              src: '/image/1-201115211451B0.jpg'
            },
            {
              name: '上海model 净身高180 D（车展，',
              src: '/image/1-2011031G345253.jpg'
            },
            {
              name: '上海.171D平面model 擅长钢琴',
              src: '/image/1-2011031G0122F.jpg'
            },
            {
              name: '上海99年大 身高172 95斤 天然F',
              src: '/image/1-2011031FA2362.jpg'
            },
            {
              name: '上海初恋系国际贸易 5',
              src: '/image/1-2011031F52J28.jpg'
            },
            {
              name: '上海模特170D 性感翘臀 黄金身材比例',
              src: '/image/1-2011031F226406.jpg'
            },
            {
              name: '上海极品 168 E+ 白到发光 5',
              src: '/image/1-201005163243405.jpg'
            },
            {
              name: '上海 173E，全身雪白，S身材蜜桃',
              src: '/image/1-20100516291GF.jpg'
            },
            {
              name: '上海 172f韩国留學生 专业舞蹈十級',
              src: '/image/1-20100416400I63.jpg'
            }
          ]
        },
        {
          city: '广州',
          list: [
            {
              name: '广州健身教练',
              src: '/image/1-20111521553X25.jpg'
            },
            {
              name: '广州 175 D',
              src: '/image/1-201115214959459.jpg'
            },
            {
              name: '广州双胞胎',
              src: '/image/1-20091213412W94.jpg'
            },
            {
              name: '广州 168D 98年',
              src: '/image/1-200RR0323U42.jpg'
            },
            {
              name: '广州 177E 兼职模特 瑜伽教练',
              src: '/image/1-200GQZSUM.jpg'
            },
            {
              name: '广州海航175D',
              src: '/image/1-200GQZG9256.jpg'
            },
            {
              name: '广州175E',
              src: '/image/1-200GQZ604432.jpg'
            },
            {
              name: '广州 175E',
              src: '/image/1-200FQ9560G34.jpg'
            },
            {
              name: '广州 176E',
              src: '/image/1-200FQ95552b0.jpg'
            },
            {
              name: '广州 175F',
              src: '/image/1-200FQ95535551.jpg'
            }
          ]
        },
        {
          city: '深圳',
          list: [
            {
              name: '深圳 日本人',
              src: '/image/1-201115212435U0.jpg'
            },
            {
              name: '深圳 00年172e极品网红 5',
              src: '/image/1-201115212009408.jpg'
            },
            {
              name: '深圳人身高175D 白 乖巧',
              src: '/image/1-201102203144305.jpg'
            },
            {
              name: '深圳 网红174/E/00',
              src: '/image/1-20110220264D09.jpg'
            },
            {
              name: '深圳 极品175cm 模特',
              src: '/image/1-20110220251B01.jpg'
            },
            {
              name: '深圳17036D 真胸',
              src: '/image/1-201102202143M0.jpg'
            },
            {
              name: '深圳 魔鬼身材. 皮肤白暂 净身高175',
              src: '/image/1-2011022020151E.jpg'
            },
            {
              name: '深圳中韩混血儿170G乳真',
              src: '/image/1-200Q1161P0245.jpg'
            },
            {
              name: '深圳北京电影学院表演系 5',
              src: '/image/1-200Q1155ZK02.jpg'
            },
            {
              name: '深圳 5',
              src: '/image/1-200Q1154Z3338.jpg'
            }
          ]
        },
        {
          city: '成都',
          list: [
            {
              name: '成都 176D',
              src: '/image/1-200FQ95232216.jpg'
            },
            {
              name: '成都 173E',
              src: '/image/1-200FQ952143L.jpg'
            },
            {
              name: '成都 173E',
              src: '/image/1-200FQ95155612.jpg'
            },
            {
              name: '成都 169G',
              src: '/image/1-200FQ9513O42.jpg'
            },
            {
              name: '成都 170D',
              src: '/image/1-200FQ9505G41.jpg'
            },
            {
              name: '成都 167D',
              src: '/image/1-200FQ9503c26.jpg'
            },
            {
              name: '贵阳 169D',
              src: '/image/1-20060221021M27.jpg'
            },
            {
              name: '成都 173E 18',
              src: '/image/1-2006022059352L.jpg'
            },
            {
              name: '成都',
              src: '/image/1-200514212434564.jpg'
            },
            {
              name: '成都 168 大D 49kg',
              src: '/image/1-200616135431J5.jpg'
            }
          ]
        },
        {
          city: '杭州',
          list: [
            {
              name: '杭州168F',
              src: '/image/1-200ZG52Z53I.jpg'
            },
            {
              name: '杭州极品 净高176/D 车模',
              src: '/image/1-200ZG52P2633.jpg'
            },
            {
              name: '杭州 170D 5起',
              src: '/image/1-200RR03401K5.jpg'
            },
            {
              name: '杭州 168G',
              src: '/image/1-200RR033304V.jpg'
            },
            {
              name: '杭州 168C 03年',
              src: '/image/1-200RR0330G35.jpg'
            },
            {
              name: '杭州 165B',
              src: '/image/1-200Q623163E09.jpg'
            },
            {
              name: '杭州 174E',
              src: '/image/1-200Q623161Ta.jpg'
            },
            {
              name: '杭州 170C',
              src: '/image/1-200Q6231600128.jpg'
            },
            {
              name: '杭州 168C 03年',
              src: '/image/1-200Q6231545207.jpg'
            },
            {
              name: '杭州 168D 01年',
              src: '/image/1-200Q623151QU.jpg'
            }
          ]
        },
        {
          city: '武汉',
          list: [
            {
              name: '武汉 170C',
              src: '/image/1-20062H25042331.jpg'
            },
            {
              name: '武汉 170D',
              src: '/image/1-20062H25000J5.jpg'
            },
            {
              name: '武汉 167D 00年',
              src: '/image/1-20062H24925L6.jpg'
            },
            {
              name: '武汉 170D 96年',
              src: '/image/1-20061521495U44.jpg'
            },
            {
              name: '武汉 175F',
              src: '/image/1-200615214921T5.jpg'
            },
            {
              name: '武汉 175F',
              src: '/image/1-200615214Z0A9.jpg'
            },
            {
              name: '武汉 168C 00年',
              src: '/image/1-20061521425X37.jpg'
            },
            {
              name: '武汉 172E',
              src: '/image/1-200615214214530.jpg'
            },
            {
              name: '武汉 168D 00年',
              src: '/image/1-200615214155B0.jpg'
            },
            {
              name: '武汉 173E',
              src: '/image/1-20061521411R44.jpg'
            }
          ]
        },
        {
          city: '天津',
          list: [
            {
              name: '天津 健身教练 气质可爱 168天然E杯',
              src: '/image/1-200P31G55M61.jpg'
            },
            {
              name: '天津 真实身高170cm 胸围E',
              src: '/image/1-200P31G031I8.jpg'
            },
            {
              name: '天津176E，初次抵达，皮肤雪白',
              src: '/image/1-200P31F341Z1.jpg'
            },
            {
              name: '天津',
              src: '/image/1-200511131P4626.jpg'
            },
            {
              name: '天津 173 D',
              src: '/image/1-200421210255H7.jpg'
            },
            {
              name: '天津新人骚蜜170大H，南方女孩性格好可',
              src: '/image/1-1910121I20a00.jpg'
            },
            {
              name: '天津02年小孩??刚下氵 纯?人170C',
              src: '/image/1-1910121I14NN.jpg'
            },
            {
              name: '天津首次大韩空姐174 F 新加坡留学',
              src: '/image/1-1910121I02cD.jpg'
            },
            {
              name: '天津日语一级 英语六级 空姐 制服证件齐',
              src: '/image/1-1910121H941359.jpg'
            }
          ]
        },
        {
          city: '南京',
          list: [
            {
              name: '南京 173D',
              src: '/image/1-200523222914924.jpg'
            },
            {
              name: '南京 净高177E',
              src: '/image/1-2005232223494T.jpg'
            },
            {
              name: '南京，身高173E 身材苗条',
              src: '/image/1-200523221F6102.jpg'
            },
            {
              name: '南京 私人健身教练170',
              src: '/image/1-20052322153I47.jpg'
            },
            {
              name: '南京 170D',
              src: '/image/1-200523221035D0.jpg'
            },
            {
              name: '南京海航空姐168真D',
              src: '/image/1-200523220Z5K9.jpg'
            },
            {
              name: '南京 人170D',
              src: '/image/1-20052322050W20.jpg'
            },
            {
              name: '南京 168d，美国留学生，',
              src: '/image/1-200523215H0V5.jpg'
            },
            {
              name: '南京人172D',
              src: '/image/1-2005232132422Y.jpg'
            }
          ]
        },
        {
          city: '重庆',
          list: [
            {
              name: '重庆166 c 00后',
              src: '/image/1-201004163QA44.jpg'
            },
            {
              name: '重庆 171D',
              src: '/image/1-20061219335WW.jpg'
            },
            {
              name: '重庆 170D',
              src: '/image/1-200612193341134.jpg'
            },
            {
              name: '重庆 173D',
              src: '/image/1-200612193324133.jpg'
            },
            {
              name: '重庆 174D',
              src: '/image/1-200612193303225.jpg'
            },
            {
              name: '重庆 166 C',
              src: '/image/1-200602211J0a4.jpg'
            },
            {
              name: '重庆174 f',
              src: '/image/1-20060221152aX.jpg'
            },
            {
              name: '重庆 170E',
              src: '/image/1-200602211330149.jpg'
            }
          ]
        },
        {
          city: '西安',
          list: [
            {
              name: '西安 172C 90斤 筷子腿',
              src: '/image/1-200922134330L6.jpg'
            },
            {
              name: '西安美院学生 身高170cm_胸围D杯_',
              src: '/image/1-20042R20439B3.jpg'
            },
            {
              name: '西安 身高175cm_胸围E杯_95年',
              src: '/image/1-20042R20032342.jpg'
            },
            {
              name: '西安170 Ｃ 短期兼职西工大航空系学生',
              src: '/image/1-20042R15QV40.jpg'
            },
            {
              name: '西安净足175 D 平面模特',
              src: '/image/1-20042R155393S.jpg'
            },
            {
              name: '西安170 E 99年 瑜伽健身教练 身',
              src: '/image/1-20042R15432300.jpg'
            },
            {
              name: '西安暂时停留。172 E',
              src: '/image/1-20042321013R94.jpg'
            }
          ]
        },
        {
          city: '长沙',
          list: [
            {
              name: '长沙 175E',
              src: '/image/1-200FQ95005458.jpg'
            },
            {
              name: '长沙 173D',
              src: '/image/1-200FQ94945O6.jpg'
            },
            {
              name: '长沙 173C',
              src: '/image/1-200FQ9492BQ.jpg'
            },
            {
              name: '长沙 165D',
              src: '/image/1-200FQ94UR43.jpg'
            },
            {
              name: '长沙 165D',
              src: '/image/1-200FQ94SW33.jpg'
            },
            {
              name: '长沙 170D',
              src: '/image/1-200FQ94Q5949.jpg'
            },
            {
              name: '长沙 170C',
              src: '/image/1-200FQ94K0E5.jpg'
            },
            {
              name: '长沙 170C',
              src: '/image/1-200FQ94F0633.jpg'
            },
            {
              name: '长沙 176E',
              src: '/image/1-2006091925463O.jpg'
            },
            {
              name: '长沙177e',
              src: '/image/1-200609192521C5.jpg'
            }
          ]
        },
        {
          city: '青岛',
          list: [
            {
              name: '青岛 172D 5起',
              src: '/image/1-200RR0351O48.jpg'
            },
            {
              name: '青岛 172D',
              src: '/image/1-200RR03501426.jpg'
            },
            {
              name: '青岛 172H',
              src: '/image/1-200RR03439A6.jpg'
            },
            {
              name: '青岛 175E',
              src: '/image/1-20062Q104304M.jpg'
            },
            {
              name: '青岛 169C',
              src: '/image/1-200620195103H5.jpg'
            },
            {
              name: '青岛 175C',
              src: '/image/1-200620195034507.jpg'
            },
            {
              name: '青岛 175C',
              src: '/image/1-20062019500C06.jpg'
            },
            {
              name: '青岛168E 02年',
              src: '/image/1-20062019493c47.jpg'
            },
            {
              name: '青岛167D 00年',
              src: '/image/1-200620194915109.jpg'
            },
            {
              name: '青岛 177F',
              src: '/image/1-200620194Rb14.jpg'
            }
          ]
        },
        {
          city: '沈阳',
          list: [
            {
              name: '沈阳 ? 97年 173 D极品 皮肤',
              src: '/image/1-1910191IHL20.jpg'
            },
            {
              name: '沈阳 气质白领长腿 175 D 性格超',
              src: '/image/1-1910191I612435.jpg'
            },
            {
              name: '沈阳 170d 00年沈阳大学学生 清纯',
              src: '/image/1-1910191I116308.jpg'
            },
            {
              name: '沈阳医药大学 99年，身高 166 ，C',
              src: '/image/1-1910191I02A62.jpg'
            },
            {
              name: '沈阳 170C',
              src: '/image/1-1910191H9223c.jpg'
            },
            {
              name: '沈阳 极品 170C 乖巧',
              src: '/image/1-1910191HU3A5.jpg'
            },
            {
              name: '沈阳新人 健身教练兼职 温柔大长腿',
              src: '/image/1-1910191HA2M0.jpg'
            },
            {
              name: '沈阳新人 车模兼职健身教练 温柔大长腿',
              src: '/image/1-1910191H600918.jpg'
            },
            {
              name: '沈阳，175F，真，身材好到极致',
              src: '/image/1-1910191H523a8.jpg'
            },
            {
              name: '沈阳',
              src: '/image/1-1910191H40N94.jpg'
            }
          ]
        },
        {
          city: '大连',
          list: [
            {
              name: '大连172e 气质模特',
              src: '/image/1-200922133SJ60.jpg'
            },
            {
              name: '大连音乐学院在校生 175D',
              src: '/image/1-20051G52FE01.jpg'
            },
            {
              name: '大连 170C 00 c',
              src: '/image/1-20051G52412D2.jpg'
            },
            {
              name: '大连99年，真D 166',
              src: '/image/1-20051G5193IS.jpg'
            },
            {
              name: '大连极品模，99年174cm95斤，天然',
              src: '/image/1-20051G51P4O7.jpg'
            },
            {
              name: '江南美女 172 长腿 c',
              src: '/image/1-20051G51543J0.jpg'
            },
            {
              name: '大连 身高170cm 体重49kg 98',
              src: '/image/1-200513151924127.jpg'
            }
          ]
        },
        {
          city: '厦门',
          list: [
            {
              name: '厦门 171F',
              src: '/image/1-20062R24U51c.jpg'
            },
            {
              name: '厦门 171E 99年',
              src: '/image/1-20062R24R4M7.jpg'
            },
            {
              name: '厦门 174D',
              src: '/image/1-20062R24K1Q2.jpg'
            },
            {
              name: '厦门 170E',
              src: '/image/1-20062R24Ha53.jpg'
            },
            {
              name: '厦门 172D',
              src: '/image/1-20062R24F2Z6.jpg'
            },
            {
              name: '厦门 168D',
              src: '/image/1-20062R246321b.jpg'
            },
            {
              name: '厦门 170D',
              src: '/image/1-20062R24550296.jpg'
            },
            {
              name: '厦门 176D',
              src: '/image/1-20062R24511491.jpg'
            },
            {
              name: '厦门 175E',
              src: '/image/1-200616195600Y6.jpg'
            },
            {
              name: '厦门 169E',
              src: '/image/1-20061619553V29.jpg'
            }
          ]
        },
        {
          city: '苏州',
          list: [
            {
              name: '苏州 清纯 170D 00年',
              src: '/image/1-201004163643L3.jpg'
            },
            {
              name: '苏州 170、真实D，01年，',
              src: '/image/1-201004163525134.jpg'
            },
            {
              name: '三亚168D 皮肤雪白',
              src: '/image/1-2005201H636438.jpg'
            },
            {
              name: '苏州外国 人',
              src: '/image/1-2005201GF4Q8.jpg'
            },
            {
              name: '苏州日系漫画少女 165 Ｃ 01',
              src: '/image/1-200512002421M9.jpg'
            },
            {
              name: '苏州',
              src: '/image/1-200512002303613.jpg'
            },
            {
              name: '苏州人芭蕾舞蹈老师168cm',
              src: '/image/1-2005120021535S.jpg'
            },
            {
              name: '苏州人真实瑜伽教练 168cm',
              src: '/image/1-200512002112U3.jpg'
            },
            {
              name: '苏州人 168c',
              src: '/image/1-200512002000252.jpg'
            },
            {
              name: '苏州艺校学妹166D',
              src: '/image/1-200512001UC31.jpg'
            }
          ]
        },
        {
          city: '宁波',
          list: [
            {
              name: '宁波 170C 01年',
              src: '/image/1-200Q623210M09.jpg'
            },
            {
              name: '宁波 166C 02年',
              src: '/image/1-200Q623203T60.jpg'
            },
            {
              name: '宁波 168D',
              src: '/image/1-200Q6232023560.jpg'
            },
            {
              name: '宁波 167C',
              src: '/image/1-200Q6232005200.jpg'
            },
            {
              name: '宁波 172D',
              src: '/image/1-200Q6231946334.jpg'
            },
            {
              name: '宁波 171D',
              src: '/image/1-200Q6231925K8.jpg'
            },
            {
              name: '宁波 165C',
              src: '/image/1-200Q6231ZKW.jpg'
            },
            {
              name: '宁波 173D',
              src: '/image/1-200Q6231T9411.jpg'
            },
            {
              name: '宁波 166E',
              src: '/image/1-200Q6231S22W.jpg'
            },
            {
              name: '宁波 166D',
              src: '/image/1-200Q6231Q51C.jpg'
            }
          ]
        },
        {
          city: '无锡',
          list: [
            {
              name: '无锡 172E 97年',
              src: '/image/1-2006221ZS4616.jpg'
            },
            {
              name: '无锡 174D 南航',
              src: '/image/1-2006221ZKE04.jpg'
            },
            {
              name: '无锡 170D',
              src: '/image/1-2006221ZILD.jpg'
            },
            {
              name: '无锡 170C',
              src: '/image/1-2006221ZF4561.jpg'
            },
            {
              name: '无锡 172D',
              src: '/image/1-2006221Z644L3.jpg'
            },
            {
              name: '无锡 169D',
              src: '/image/1-2006221Z6101A.jpg'
            },
            {
              name: '无锡 170D',
              src: '/image/1-2006221Z544Y1.jpg'
            },
            {
              name: '无锡 173D',
              src: '/image/1-2006221Z52ED.jpg'
            },
            {
              name: '无锡 168D',
              src: '/image/1-20060QS353111.png'
            },
            {
              name: '无锡170D 46kg',
              src: '/image/1-20060QS3423c.png'
            }
          ]
        },
        {
          city: '福州',
          list: [
            {
              name: '福州新人宝宝，169D',
              src: '/image/1-200P51H333511.jpg'
            },
            {
              name: '福州瑜伽教练，身高168， D胸',
              src: '/image/1-20051319403KX.jpg'
            },
            {
              name: '福州 170DV 58kg',
              src: '/image/1-200513193F2453.jpg'
            },
            {
              name: '福州170E气质网红',
              src: '/image/1-2005131ZR2519.jpg'
            },
            {
              name: '福州瑜伽教练，身高168， D胸，皮肤白',
              src: '/image/1-20051319403KX.jpg'
            },
            {
              name: '福州蒙古族大蜜 会唱蒙古歌曲 会蒙语 1',
              src: '/image/1-200412005955940.jpg'
            },
            {
              name: '福州?人 琪琪 172cm C 92斤',
              src: '/image/1-200411225K3912.jpg'
            },
            {
              name: '福州 98年，身高170 体重98，',
              src: '/image/1-2004112240034U.jpg'
            },
            {
              name: '福州 西170D，58kg 成熟气质',
              src: '/image/1-200411223U62N.jpg'
            }
          ]
        },
        {
          city: '合肥',
          list: [
            {
              name: '合肥 173D',
              src: '/image/1-20062Q111501Z.jpg'
            },
            {
              name: '合肥 173D',
              src: '/image/1-20062Q1112b58.jpg'
            },
            {
              name: '合肥 166E',
              src: '/image/1-20062Q11109457.jpg'
            },
            {
              name: '合肥 170D',
              src: '/image/1-20062Q11041349.jpg'
            },
            {
              name: '合肥 175D',
              src: '/image/1-20062019410H07.jpg'
            },
            {
              name: '合肥 170G',
              src: '/image/1-20062019404LM.jpg'
            },
            {
              name: '合肥 173D',
              src: '/image/1-200620194025X8.jpg'
            },
            {
              name: '合肥 175E',
              src: '/image/1-200620194005J4.jpg'
            },
            {
              name: '合肥 175E',
              src: '/image/1-200620193Z1403.jpg'
            },
            {
              name: '合肥 168D 00年',
              src: '/image/1-200620193Rc64.jpg'
            }
          ]
        },
        {
          city: '郑州',
          list: [
            {
              name: '郑州 175D',
              src: '/image/1-20060919350A49.jpg'
            },
            {
              name: '郑州 168 94斤 21s',
              src: '/image/1-200609193344939.jpg'
            },
            {
              name: '郑州175D 98年',
              src: '/image/1-200609193311I5.jpg'
            },
            {
              name: '郑州 168D',
              src: '/image/1-2006091932355T.jpg'
            },
            {
              name: '郑州 172D 98年',
              src: '/image/1-200609193204314.jpg'
            },
            {
              name: '郑州 173E',
              src: '/image/1-20060919312C59.jpg'
            },
            {
              name: '郑州 175D',
              src: '/image/1-20060919305B09.jpg'
            },
            {
              name: '郑州 175E',
              src: '/image/1-200609193030523.jpg'
            },
            {
              name: '郑州 180C',
              src: '/image/1-20060919295a38.jpg'
            },
            {
              name: '上海 172C 95斤',
              src: '/image/1-20052416413M14.jpg'
            }
          ]
        },
        {
          city: '哈尔滨',
          list: [
            {
              name: '哈尔滨真实模特兼职171前凸后翘',
              src: '/image/1-20040422002WB.jpg'
            },
            {
              name: '哈尔滨170d 00后在校学生',
              src: '/image/1-20040421592C46.jpg'
            },
            {
              name: '哈尔滨极品00真实可验168E',
              src: '/image/1-200404215Sb24.jpg'
            },
            {
              name: '哈尔滨169d',
              src: '/image/1-200404215I5C0.jpg'
            },
            {
              name: '哈尔滨.真实00童颜巨乳.168天',
              src: '/image/1-200404215559192.jpg'
            },
            {
              name: '哈尔滨中俄混血儿 176E 00',
              src: '/image/1-20040421552E38.jpg'
            },
            {
              name: '哈尔滨 01年 166 ～C',
              src: '/image/1-200404215416233.jpg'
            }
          ]
        },
        {
          city: '济南',
          list: [
            {
              name: '济南 170D',
              src: '/image/1-20062Q11H4293.jpg'
            },
            {
              name: '济南 178D',
              src: '/image/1-20060QR3422F.jpg'
            },
            {
              name: '济南173D',
              src: '/image/1-20060QR049134.jpg'
            },
            {
              name: '济南 170 G',
              src: '/image/1-20060QQP41U.jpg'
            },
            {
              name: '济南 172E',
              src: '/image/1-20060QQU1228.jpg'
            },
            {
              name: '济南 178D',
              src: '/image/1-20060QI632M0.png'
            },
            {
              name: '济南175 D',
              src: '/image/1-20060QI43E27.png'
            },
            {
              name: '济南 小娜扎170',
              src: '/image/1-20060QI150E5.jpg'
            },
            {
              name: '济南 99年',
              src: '/image/1-20060QHR9455.jpg'
            },
            {
              name: '济南 178d',
              src: '/image/1-200602214I1495.jpg'
            }
          ]
        },
        {
          city: '东莞',
          list: [
            {
              name: '东莞 168E',
              src: '/image/1-20062Q11JX48.jpg'
            }
          ]
        },
        {
          city: '昆明',
          list: [
            {
              name: '昆明舞蹈老师 一枚 170D，身材辣，乖',
              src: '/image/1-200P31A422648.jpg'
            },
            {
              name: '昆明新人 168C 兼职开',
              src: '/image/1-200P31A309301.jpg'
            },
            {
              name: '昆明 172D 00年',
              src: '/image/1-20062Q10952129.jpg'
            },
            {
              name: '昆明 172D 98年',
              src: '/image/1-20062Q10Z9546.jpg'
            },
            {
              name: '昆明 168D 00年',
              src: '/image/1-20062Q10T61X.jpg'
            },
            {
              name: '昆明 170E',
              src: '/image/1-20062Q10R0125.jpg'
            },
            {
              name: '昆明 166D',
              src: '/image/1-20062Q10JbR.jpg'
            },
            {
              name: '昆明 168F',
              src: '/image/1-20062Q10HIX.jpg'
            },
            {
              name: '昆明 172D',
              src: '/image/1-20062Q10535K6.jpg'
            },
            {
              name: '昆明学生166d',
              src: '/image/1-200510225259259.jpg'
            }
          ]
        },
        {
          city: '太原',
          list: [
            {
              name: '太原 170E',
              src: '/image/1-200F4233952O9.jpg'
            },
            {
              name: '太原 178D',
              src: '/image/1-200F4233919624.jpg'
            },
            {
              name: '太原 172E',
              src: '/image/1-200F4233TXJ.jpg'
            },
            {
              name: '太原 178E',
              src: '/image/1-200F4233PR40.jpg'
            },
            {
              name: '太原 178E',
              src: '/image/1-200F4233J3308.jpg'
            },
            {
              name: '太原 170D',
              src: '/image/1-200F4233G1360.jpg'
            },
            {
              name: '太原 176C 99年',
              src: '/image/1-200630212214954.jpg'
            },
            {
              name: '太原 170C 99年',
              src: '/image/1-20063021213JM.jpg'
            },
            {
              name: '太原 175D',
              src: '/image/1-200630212003555.jpg'
            },
            {
              name: '太原身高172 天然真',
              src: '/image/1-200510142540a9.jpg'
            }
          ]
        },
        {
          city: '南昌',
          list: [
            {
              name: '南昌 166 34e 50kg',
              src: '/image/1-200609191631428.jpg'
            },
            {
              name: '南昌 170 80E/ 00',
              src: '/image/1-200609191551110.jpg'
            },
            {
              name: '南昌 168D 98年',
              src: '/image/1-200609191459206.jpg'
            },
            {
              name: '南昌，身高170.d',
              src: '/image/1-20051Q6254CC.jpg'
            },
            {
              name: '南昌167cm D',
              src: '/image/1-20051Q62359321.jpg'
            },
            {
              name: '南昌168cm 抖音网红',
              src: '/image/1-20051Q62224F9.jpg'
            },
            {
              name: '南昌',
              src: '/image/1-20051Q6203T64.jpg'
            },
            {
              name: '南昌 美术学院极品校花170 D 99',
              src: '/image/1-20051Q61R5F2.jpg'
            },
            {
              name: '168D南昌',
              src: '/image/1-20051Q6093Y61.jpg'
            },
            {
              name: '南昌 车模172/98c碧玺',
              src: '/image/1-20051Q60643642.jpg'
            }
          ]
        },
        {
          city: '温州',
          list: [
            {
              name: '温州 170D',
              src: '/image/1-20062Q11643D9.jpg'
            },
            {
              name: '温州 172C',
              src: '/image/1-20062Q11533927.jpg'
            },
            {
              name: '温州 170D',
              src: '/image/1-20062Q1150Q00.jpg'
            },
            {
              name: '温州 173E',
              src: '/image/1-20062Q1143c34.jpg'
            },
            {
              name: '温州 173 36D',
              src: '/image/1-20062Q1141S02.jpg'
            },
            {
              name: '温州 168 00年',
              src: '/image/1-20062Q1135J00.jpg'
            },
            {
              name: '温州 170D 00年',
              src: '/image/1-20062Q11331A9.jpg'
            },
            {
              name: '温州 170E 00年',
              src: '/image/1-20062Q11303D8.jpg'
            },
            {
              name: '温州170D',
              src: '/image/1-200411221251T1.jpg'
            },
            {
              name: '温州有舞蹈基础 170E',
              src: '/image/1-200411221133914.jpg'
            }
          ]
        },
        {
          city: '石家庄',
          list: [
            {
              name: '石家庄 172D 新人',
              src: '/image/1-200RR03926333.jpg'
            },
            {
              name: '石家庄 175D',
              src: '/image/1-200RR03ZLD.jpg'
            },
            {
              name: '石家庄 172D',
              src: '/image/1-200RR03U3125.jpg'
            },
            {
              name: '石家庄 172D',
              src: '/image/1-200RR03S4618.jpg'
            },
            {
              name: '石家庄 170C',
              src: '/image/1-200RR03Q2318.jpg'
            },
            {
              name: '石家庄，180 真D，戏剧学院学生，',
              src: '/image/1-200P31630463H.jpg'
            },
            {
              name: '石家庄 99年 170D',
              src: '/image/1-200P3162SR30.jpg'
            },
            {
              name: '石家庄纯 人净身高170cm',
              src: '/image/1-200P3162I15a.jpg'
            },
            {
              name: '石家庄00 极品 175D',
              src: '/image/1-200P3162A13W.jpg'
            },
            {
              name: '石家庄174D 00年A4腰翘臀',
              src: '/image/1-200P316240N12.jpg'
            }
          ]
        },
        {
          city: '长春',
          list: [
            {
              name: '长春吉林艺术学院舞蹈系小花旦！ 168，',
              src: '/image/1-2009161HF1923.jpg'
            },
            {
              name: '长春平面模特170E 网拍网红',
              src: '/image/1-2009161H502343.jpg'
            },
            {
              name: '长春全身雪白！170，36d',
              src: '/image/1-2009161H243137.jpg'
            },
            {
              name: '长春 168，C艺术学院舞蹈系校花',
              src: '/image/1-2009161ASO53.jpg'
            },
            {
              name: '长春人168 平面模特，时尚达人',
              src: '/image/1-2009161A620G0.jpg'
            },
            {
              name: '长春 168d',
              src: '/image/1-200Q1152Q5B7.jpg'
            },
            {
              name: '长春，168d清纯可爱，',
              src: '/image/1-200Q1152223517.jpg'
            },
            {
              name: '长春极品白小纯165D',
              src: '/image/1-200Q11520195c.jpg'
            },
            {
              name: '长春 净身高166 C，真实98年',
              src: '/image/1-2005211A501164.jpg'
            },
            {
              name: '长春舞蹈老师175真E',
              src: '/image/1-200513224A2c1.jpg'
            }
          ]
        },
        {
          city: '泉州',
          list: [
            {
              name: '泉州有酒窝',
              src: '/image/1-200511142943H7.jpg'
            }
          ]
        },
        {
          city: '贵阳',
          list: [
            {
              name: '贵阳 170 D',
              src: '/image/1-20051QHP1455.jpg'
            },
            {
              name: '贵阳168c，97年',
              src: '/image/1-20042123260c05.jpg'
            },
            {
              name: '贵阳171 c',
              src: '/image/1-20042123251c37.jpg'
            },
            {
              name: '贵阳 172d 00后',
              src: '/image/1-20042122025U01.jpg'
            },
            {
              name: '贵阳学生 168c 00后',
              src: '/image/1-200421220223Q6.jpg'
            },
            {
              name: '贵阳166 C',
              src: '/image/1-200421215955N8.jpg'
            },
            {
              name: '贵阳极品车模',
              src: '/image/1-200421215R5320.jpg'
            }
          ]
        },
        {
          city: '常州',
          list: [
            {
              name: '常州168D韩国人',
              src: '/image/1-20051Q60013555.jpg'
            },
            {
              name: '常州，02年78斤',
              src: '/image/1-200510153012a4.jpg'
            },
            {
              name: '常州 168d 02年45kg',
              src: '/image/1-200510152T3F3.jpg'
            },
            {
              name: '常州 173D',
              src: '/image/1-200510152K2116.jpg'
            },
            {
              name: '常州00 172 D',
              src: '/image/1-200510152634159.jpg'
            },
            {
              name: '常 州 173/c 00年',
              src: '/image/1-2005101525429E.jpg'
            },
            {
              name: '常州 169D',
              src: '/image/1-20051015241Gb.jpg'
            }
          ]
        },
        {
          city: '珠海',
          list: [
            {
              name: '珠海 170 E',
              src: '/image/1-200511144Q5140.jpg'
            },
            {
              name: '珠海新人??00后168D清纯无整容',
              src: '/image/1-191014100559233.jpg'
            },
            {
              name: '珠海 173D',
              src: '/image/1-19101410052UG.jpg'
            },
            {
              name: '珠海175c极品网红',
              src: '/image/1-1910141003402Z.jpg'
            },
            {
              name: '珠海极品白小纯，163c 00',
              src: '/image/1-191014100051214.jpg'
            },
            {
              name: '珠海少妇 173D',
              src: '/image/1-19101410001bG.jpg'
            },
            {
              name: '珠海新人良家小可爱168c',
              src: '/image/1-191014095Zba.jpg'
            },
            {
              name: '珠海175c极品网红',
              src: '/image/1-191014095Z1W5.jpg'
            },
            {
              name: '珠海175c极品网红',
              src: '/image/1-1910141003402Z.jpg'
            }
          ]
        },
        {
          city: '海口',
          list: [
            {
              name: '海口白小纯167C',
              src: '/image/1-1910241319244C.jpg'
            },
            {
              name: '海口白小纯167C',
              src: '/image/1-191024131H09D.jpg'
            },
            {
              name: '海口170真实E 一个爱笑的姑娘',
              src: '/image/1-191024131643535.jpg'
            },
            {
              name: '海口学生2002年，纯天然，在校生，身高',
              src: '/image/1-191024131534923.jpg'
            }
          ]
        },
        {
          city: '乌鲁木齐',
          list: [
            {
              name: '乌鲁木齐真实 ·175',
              src: '/image/1-20052QA031Y8.jpg'
            },
            {
              name: '乌鲁木齐',
              src: '/image/1-20050H1464NH.jpg'
            },
            {
              name: '乌鲁木齐 首次 168 D',
              src: '/image/1-20050H14121X0.jpg'
            },
            {
              name: '乌鲁木齐 维吾尔族168D',
              src: '/image/1-20050H140211b.jpg'
            }
          ]
        },
        {
          city: '徐州',
          list: [
            {
              name: '徐州',
              src: '/image/1-20051216222NA.jpg'
            },
            {
              name: '徐州净身高172 23岁 天然真',
              src: '/image/1-20050H2011aX.jpg'
            },
            {
              name: '徐州170 36D48kg',
              src: '/image/1-20050H15H6333.jpg'
            },
            {
              name: '徐州166C',
              src: '/image/1-20050H1562GI.jpg'
            }
          ]
        },
        {
          city: '嘉兴',
          list: [
            {
              name: '嘉兴 身高176',
              src: '/image/1-200ZG530425J.jpg'
            },
            {
              name: '绍兴02年，165D',
              src: '/image/1-200512161322557.jpg'
            },
            {
              name: '绍兴 172cm. C.50kg.',
              src: '/image/1-200512161120449.jpg'
            },
            {
              name: '绍兴170 C 99',
              src: '/image/1-200512161035R5.jpg'
            },
            {
              name: '嘉兴人，172 D 00年',
              src: '/image/1-200512155GCF.jpg'
            },
            {
              name: '嘉兴173e',
              src: '/image/1-200512155401349.jpg'
            },
            {
              name: '嘉兴学生兼职 净168 D',
              src: '/image/1-200512154TQU.jpg'
            },
            {
              name: '嘉兴 172E',
              src: '/image/1-20051215451OH.jpg'
            },
            {
              name: '嘉兴173E',
              src: '/image/1-20051215430B94.jpg'
            }
          ]
        },
        {
          city: '洛阳',
          list: [
            {
              name: '洛阳 00，168d，学生',
              src: '/image/1-200P315551C44.jpg'
            },
            {
              name: '洛阳人168D 温柔体贴',
              src: '/image/1-200P3155414c7.jpg'
            },
            {
              name: '洛阳人 真实00年 168C',
              src: '/image/1-200P3155302P1.jpg'
            },
            {
              name: '洛阳',
              src: '/image/1-200510153J5362.jpg'
            }
          ]
        },
        {
          city: '南通',
          list: [
            {
              name: '南通新人东航空姐172E',
              src: '/image/1-200P31J1549D.jpg'
            },
            {
              name: '南通 173C 98年',
              src: '/image/1-20061620002E41.jpg'
            },
            {
              name: '南通 171D',
              src: '/image/1-200616195953529.jpg'
            },
            {
              name: '南通 171D',
              src: '/image/1-20061619592BN.jpg'
            },
            {
              name: '南通 168 游泳教练',
              src: '/image/1-20051QI32X44.jpg'
            },
            {
              name: '南通在校学生 172真实C',
              src: '/image/1-20051015505a50.jpg'
            },
            {
              name: '南通170d时装模特',
              src: '/image/1-200510155023D3.jpg'
            },
            {
              name: '南通 师范大学在读 韩系氧气',
              src: '/image/1-200510154Z33C.jpg'
            }
          ]
        },
        {
          city: '扬州',
          list: [
            {
              name: '扬州 小网红165C',
              src: '/image/1-200920135239536.jpg'
            },
            {
              name: '扬 州 170 E奶 肤白貌美',
              src: '/image/1-200920134953453.jpg'
            },
            {
              name: '扬州 净身高175 - D 0整容',
              src: '/image/1-200920134U1B8.jpg'
            },
            {
              name: '扬州',
              src: '/image/1-200511140110919.jpg'
            }
          ]
        },
        {
          city: '兰州',
          list: [
            {
              name: '兰州 170D',
              src: '/image/1-200P3163440291.jpg'
            },
            {
              name: '兰州舞蹈演员168cm,36D,48kg',
              src: '/image/1-200P3163320614.jpg'
            },
            {
              name: '兰州刚下水 166D小花极品',
              src: '/image/1-200P3163143164.jpg'
            },
            {
              name: '银川00年',
              src: '/image/1-200P31559303H.jpg'
            },
            {
              name: '西宁 98年165D皮肤超白',
              src: '/image/1-20051316402b44.jpg'
            },
            {
              name: '西宁 00年净身高166，98斤D胸',
              src: '/image/1-200513163913H7.jpg'
            },
            {
              name: '兰州 173E极品气质女人',
              src: '/image/1-191015192939347.jpg'
            },
            {
              name: '兰州身高170 胸围36D',
              src: '/image/1-1910151929212L.jpg'
            },
            {
              name: '兰州 170G 媚眼如丝',
              src: '/image/1-191015192J1402.jpg'
            },
            {
              name: '兰州 新人175c ，可纯可蜜',
              src: '/image/1-191015192F0W0.jpg'
            }
          ]
        },
        {
          city: '桂林',
          list: [
            {
              name: '桂林 净169c',
              src: '/image/1-2005201G226202.jpg'
            },
            {
              name: '桂林170D',
              src: '/image/1-2005201FSI52.jpg'
            }
          ]
        },
        {
          city: '呼和浩特',
          list: [
            {
              name: '呼和浩特 169 童颜巨乳 E 真胸 9',
              src: '/image/1-20092213402I51.jpg'
            },
            {
              name: '呼和浩特 176D',
              src: '/image/1-20062R25223529.jpg'
            },
            {
              name: '呼和浩特 177D',
              src: '/image/1-20062R2515b38.jpg'
            },
            {
              name: '呼和浩特 173C',
              src: '/image/1-20062R25139620.jpg'
            },
            {
              name: '呼和浩特 168E 99年',
              src: '/image/1-20062R2510c27.jpg'
            },
            {
              name: '呼和浩特 177D',
              src: '/image/1-20062R2504KK.jpg'
            },
            {
              name: '呼和浩特 170C',
              src: '/image/1-20062R25000551.jpg'
            },
            {
              name: '呼和浩特 170C 00年',
              src: '/image/1-20062019423O20.jpg'
            },
            {
              name: '呼和浩特 167E',
              src: '/image/1-200620194215563.jpg'
            },
            {
              name: '呼和浩特172C',
              src: '/image/1-20062019415N93.jpg'
            }
          ]
        },
        {
          city: '绍兴',
          list: [
            {
              name: '绍兴',
              src: '/image/1-200421205HcT.jpg'
            }
          ]
        },
        {
          city: '台州',
          list: [
            {
              name: '台州 01年170E肤白貌美',
              src: '/image/1-200916164I2959.jpg'
            },
            {
              name: '台州人 172 D 性格温柔',
              src: '/image/1-20091616445A25.jpg'
            },
            {
              name: '台州 170胸c',
              src: '/image/1-20051QK12b02.jpg'
            },
            {
              name: '台州',
              src: '/image/1-200512164455142.jpg'
            },
            {
              name: '台州',
              src: '/image/1-200512164440910.jpg'
            }
          ]
        },
        {
          city: '义乌',
          list: [
            {
              name: '义乌 170D',
              src: '/image/1-20061019333b02.jpg'
            },
            {
              name: '义乌 175E',
              src: '/image/1-200610193321603.jpg'
            },
            {
              name: '义乌 168C',
              src: '/image/1-20061019325A14.jpg'
            },
            {
              name: '义乌 168 C',
              src: '/image/1-2006101932241O.jpg'
            },
            {
              name: '义乌 177F 混血',
              src: '/image/1-20061019315NL.jpg'
            }
          ]
        },
        {
          city: '银川',
          list: [
            {
              name: '银川前台，170D',
              src: '/image/1-200P3161029642.jpg'
            },
            {
              name: '银川172d',
              src: '/image/1-200P316093C38.jpg'
            },
            {
              name: '银川168d',
              src: '/image/1-200P3160S3544.jpg'
            },
            {
              name: '银川人 170D 气质瑜伽健身达人',
              src: '/image/1-200P3160J2F5.jpg'
            },
            {
              name: '银川 00年 171 d 白',
              src: '/image/1-200P3160A6408.jpg'
            },
            {
              name: '银川 極品模特',
              src: '/image/1-200P3160544H8.jpg'
            }
          ]
        },
        {
          city: '西宁',
          list: [
            {
              name: '西宁170 E 维吾尔族混血',
              src: '/image/1-2009161634363V.jpg'
            },
            {
              name: '西宁 甜美脸 蚂蚁腰 身高：17',
              src: '/image/1-200916163331948.jpg'
            },
            {
              name: '西宁168 皮肤白皙 s身材',
              src: '/image/1-200916163231633.jpg'
            },
            {
              name: '西宁 170d',
              src: '/image/1-200916163100620.jpg'
            }
          ]
        }
      ]
    }
  },
  methods: {
    addgo(data) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/list?' + 'name=' + data.city })
      }
    },
    getAddress() {
      this.$http({
        method: 'get',
        url: 'address_list'
      }).then((res) => {
        this.addlist = res.data
      })
    }
  },
  created() {
    this.getAddress()
  }
}
</script>

<style lang="less" scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar {
  background: linear-gradient(270deg, #ff678e, #f6ce6b);
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}
::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}
::v-deep .van-tabs__line {
  background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
::v-deep .van-grid-item__content {
  background-color: transparent;
}

.card {
  background: linear-gradient(270deg, #ff678e, #f6ce6b);
  padding: 0.625rem;
  width: 95%;
  color: white;
  font-size: 0.92rem;
  margin: 0.625rem auto;
  border-radius: 0.375rem;
}
::v-deep .van-row--flex {
  height: 80px;
  line-height: 80px;
}
.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}
.rig-title {
  color: #f26998;
  font-size: 1.125rem;
}
.rig-content {
  font-size: 1.125rem;

  // margin-top: 10px;
}
.address {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 200px;
}
</style>
