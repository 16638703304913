<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="客户投注记录" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
    </div>
    <div class="search">
      <div style="margin: 10px">
        <van-row gutter="10" justify="center" align="center">
          <van-col span="8">
            <van-field v-model="filter.username" clearable name="用户名" placeholder="用户名" />
          </van-col>
          <van-col v-if="false" span="8">
            <van-field v-model="filter.dayname" @click="showday = true" name="选择时间" placeholder="选择时间" />
          </van-col>
          <van-col span="8"> <van-button @click="getUserWithdrawList()" type="primary">查询</van-button></van-col>
        </van-row>
      </div>
    </div>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-empty v-if="list.length === 0" description="数据为空！" />
        <div v-else class="item_list header">
          <van-row justify="center" align="center">
            <van-col span="5">账户</van-col>
            <van-col span="5">类型</van-col>
            <van-col span="5">购买金额</van-col>
            <van-col span="5">时间</van-col>
            <van-col span="4">详情</van-col>
          </van-row>
          <van-divider />
          <div class="item_list" v-for="(v, key) in list" :key="key">
            <van-row align="center">
              <van-col span="5">{{ v.username }}</van-col>
              <van-col span="5">{{ v.lotteryname }}</van-col>
              <van-col span="5">{{ v.money }}</van-col>
              <van-col span="5">{{ v.createtime }}</van-col>
              <van-col span="4"><van-button @click="toBetDetail(v)" type="primary">详情</van-button> </van-col>
            </van-row>
            <van-divider hairline />
            <!-- <div class="time">
            <span>审核时间：{{ v.update_time }}</span>
          </div> -->
          </div>
        </div>
      </van-pull-refresh>

      <van-action-sheet v-model="showday" description="选择筛选时间" :actions="actions" cancel-text="取消" close-on-click-action @select="onSelect" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      showday: false,
      showway: false,
      actions: [
        { name: '今天', code: 1 },
        { name: '近三天', code: 3 },
        { name: '近一周', code: 7 }
      ],
      isLoading: false,
      filter: {
        day: 1,
        dayname: '一天',
        username: ''
      },
      list: []
    }
  },
  methods: {
    back() {
      return window.history.back()
    },
    toBetDetail(data) {
      this.$router.push({ path: '/BetInfo', query: { id: data.id } })
    },

    onSelect(action, index) {
      console.log(action.name)
      this.showday = false
      this.filter.day = this.actions[index].code
      this.filter.dayname = this.actions[index].name
      console.log(index)
      this.getUserWithdrawList()
    },
    selectDay() {
      this.showday = true
    },
    selectPay() {
      this.showway = true
    },
    onRefresh() {
      this.getUserWithdrawList()
    },
    getUserWithdrawList() {
      this.$http({
        method: 'post',
        url: 'agent_bet_list',
        data: this.filter
      }).then((res) => {
        if (res.code === 200) {
          this.list = res.data.data
          this.isLoading = false
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserWithdrawList()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.input {
  width: 100%;
  height: 100%;
}

.header {
  background: #9b9b9b;
}

.filter-content {
  height: 100px;
  width: 100%;
  display: flex;
  left: 0;
  align-items: center;
}

.select {
  position: relative;
  width: 50%;
  height: 100%;
  line-height: 100px;
  text-align: center;

  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
}
.filter {
  flex: 1;
  font-size: 30px;
}
.icon-right {
  margin-right: 10px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
::v-deep .van-divider {
  margin: 10px;
}

.container .main {
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list {
  padding: 5px 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  background: #fff;
  border-radius: 10px;
  font-size: 20px;
}

.item_list .topInfo span {
  flex: 1;

  overflow: hidden;
  font-weight: 700;

  color: #ff253f;
}
.item_list .time span {
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child {
  float: right;
}
.item_list .desc span {
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
</style>
