<template>
  <div class="container page">
    <van-nav-bar title="修改真实姓名" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <template #right>
        <span class="nav-right" @click="save()">保存</span>
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-field v-model="qq" label="QQ" placeholder="请输入QQ" />
      <van-field v-model="phone" label="手机号" placeholder="请输入手机号" />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      phone: '',
      qq: '',
      userInfo: {}
    }
  },
  methods: {
    back() {
      return window.history.back()
    },
    save() {
      this.$http({
        method: 'get',
        data: { qq: this.qq, phone: this.phone },
        url: 'user_set_name'
      }).then((res) => {
        if (res.code === 200) {
          this.getUserInfo()
          this.phone = this.userInfo.phone
          this.qq = this.userInfo.qq
          this.$toast(res.msg)
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data
          this.name = res.data.name
          this.phone = this.userInfo.phone
          this.qq = this.userInfo.qq
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
</style>
