<template>
  <div class="bg-container page">
    <!-- <img class="bg-img" src="img/login/login-bg.png" /> -->
    <div class="bg-wrapper">
      <div class="login">
        <van-nav-bar class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()" />
          </template>
        </van-nav-bar>
        <div class="wrapper">
          <div class="logo-container">
            <div class="logo-wrapper">
              <img class="logo-img" src="/img/logo.png" />
            </div>
          </div>
          <div class="title">登录</div>
          <div class="loginForm">
            <van-field v-model="username" clearable input-align="center" class="input" placeholder="请输入用户名" />
            <van-field v-model="password" :type="passwordType" input-align="center" class="input" placeholder="请输入密码">
              <template slot="right-icon">
                <van-icon :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'" @click="switchPasswordType" />
              </template>
            </van-field>
            <van-field v-model="usercode" clearable input-align="center" class="input" placeholder="请输入验证码">
              <template slot="right-icon">
                <van-image class="vcode-img" :src="vcode.img" @click="getVCode()" />
              </template>
            </van-field>

            <div v-if="false" class="reset-text">
              <span>忘记密码？</span>
            </div>
            <div @click="toRegister()" class="register-text">
              <span>没有账户？马上注册</span>
            </div>
            <van-button class="login-btn" type="primary" size="normal" @click="doLogin()">登 录</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      usercode: '',
      vcode: {},
      username: '',
      password: this.inputValue,
      passwordType: 'password'
    }
  },
  methods: {
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back() {
      return window.history.back()
    },
    getVCode() {
      this.$http({
        method: 'get',
        url: 'vcode'
      })
        .then((res) => {
          console.log(res)
          this.vcode = { img: res.data.img }
          console.log(this.vcode.img)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toRegister() {
      this.$router.push('Register')
    },
    doLogin() {
      if (this.username === '' || this.username === null || this.username === undefined) {
        this.$toast('请输入用户名！')
        return false
      }
      if (this.password === '' || this.password === null || this.password === undefined) {
        this.$toast('请输入密码！')
        return false
      }
      this.$http({
        method: 'get',
        data: { username: this.username, password: this.password },
        url: 'member_login'
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg)
          localStorage.setItem('token', res.data.id)
          this.$router.push('Mine')
        } else {
          this.$toast(res.msg)
        }
      })
    }
  },
  created() {
    this.getVCode()
    if (localStorage.getItem('token')) {
      return window.history.back()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';

.login {
  height: 100%;
}

.bg-container {
  background: url(/img/login/login-bg.png) no-repeat 50%/100% 100%, linear-gradient(270deg, #f9b16d, #e0ae2c);
  background-size: 100% auto;
}

.bg-container .bg-wrapper .login .nav-bar {
  background: 0 0;
}

.login .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container {
  margin: 0 auto;
  width: 55%;
}
.logo-container .logo-wrapper {
  position: relative;
  padding-bottom: 40%;
}
.logo-container .logo-wrapper .logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.login .wrapper .title {
  line-height: 100px;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  color: #fff;
  margin-top: 30px;
  letter-spacing: 5px;
}
::v-deep ::placeholder {
  color: #777777; /* 另一个输入框的提示文字颜色 */
}
.login .wrapper .loginForm {
  padding: 30px;
}
.login .wrapper .loginForm .input {
  padding: 10px 10px;
  margin-top: 40px;
  border-radius: 50px;
  text-align: center;
  line-height: 70px;
  font-size: 30px;
  color: #4e4e4e;
}
::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.login .wrapper .loginForm .reset-text {
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.login .wrapper .loginForm .reset-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.login .wrapper .loginForm .register-text {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .wrapper .loginForm .register-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 400;
  line-height: 20px;
}
.login .wrapper .loginForm .active {
}
.login .wrapper .loginForm .login-btn {
  margin-top: 85px;
  width: 100%;
  height: 90px;
  border-radius: 50px;
  color: #fff;
  background: linear-gradient(270deg, #ff678e, #f6ce6b);
  font-size: 32px;
  font-weight: 400;
  border: none;
}

.vcode-img {
  position: absolute;
  top: 6%;
  height: 88%;
  right: 0;
}
</style>
