import { render, staticRenderFns } from "./Setsex.vue?vue&type=template&id=7661606c&scoped=true"
import script from "./Setsex.vue?vue&type=script&lang=js"
export * from "./Setsex.vue?vue&type=script&lang=js"
import style0 from "./Setsex.vue?vue&type=style&index=0&id=7661606c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7661606c",
  null
  
)

export default component.exports