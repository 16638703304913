<template>
  <div class="container page">
    <van-nav-bar title="设置" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="items">
      <div class="item van-hairline--bottom" @click="toInfomation()">
        <div class="left">版本检测</div>
        <div class="right desc">当前版本为1.1.1</div>
      </div>
      <div class="item van-hairline--bottom" @click="toServicePage()">
        <div class="left">在线客服</div>
        <van-icon name="arrow" />
      </div>
      <div class="item van-hairline--bottom" @click="clearCache()">
        <div class="left">清除缓存</div>
        <van-icon name="arrow" />
      </div>
      <div class="baseinfo"></div>
      <div class="item van-hairline--bottom" @click="toInfomation()">
        <div class="left">基本信息设置</div>
        <van-icon name="arrow" />
      </div>
      <div class="item van-hairline--bottom" @click="toLoginPassword()">
        <div class="left">登录密码</div>
        <van-icon name="arrow" />
      </div>
      <div class="item van-hairline--bottom" @click="toPayPassword()">
        <div class="left">资金密码</div>
        <div class="right">
          <span class="desc">{{ this.userInfo.paypassword }}</span>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
    <van-button class="sign-out" type="primary" size="normal" @click="loginout()">退出登录</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {}
    }
  },
  methods: {
    back() {
      return window.history.back()
    },
    toPayPassword() {
      if (this.userInfo.paypassword !== '未设置') {
        this.$toast('提现密码已设置，需要修改请联系客服')
      } else {
        this.$router.push({ path: '/SetPayPassword' })
      }
    },
    toLoginPassword() {
      this.$router.push({ path: '/SetLoginPassword' })
    },
    clearCache() {
      this.$toast('正在清除缓存...')
      localStorage.clear()
      this.$router.push({ path: '/Mine' })
    },
    toInfomation() {
      this.$router.push({ path: '/Infomation' })
    },
    loginout() {
      localStorage.clear()
      this.$router.push({ path: '/Mine' })
    },
    toServicePage() {
      this.$router.push('ServicePage')
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data
          if (res.data.paypassword) {
            this.userInfo.paypassword = '已设置'
          } else {
            this.userInfo.paypassword = '未设置'
          }
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';
.container .items {
  background-color: #fff;
  font-size: 30px;
  color: #555;
}
.container .items .item {
  padding: 30px 30px 30px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 10px;
}

.container .items .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.container .sign-out {
  margin: 500px 20px 0;
  height: 100px;
  line-height: 100px;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  border: none;
  background: linear-gradient(270deg, #ff678e, #f6ce6b);
}
.container .item .desc {
  font-size: 30px;
  font-weight: 400;
  color: #979799;
}
.container .item .right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.baseinfo {
  height: 40px;
  background-color: #f2f2f5;
}
</style>
