<template>
  <div class="bg-container page">
    <img class="bg-img" src="img/login/login-bg.png" />
    <div class="bg-wrapper">
      <div class="register">
        <van-nav-bar class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()" />
          </template>
        </van-nav-bar>
        <div class="wrapper">
          <div class="logo-container">
            <div class="logo-wrapper">
              <img class="logo-img" src="/img/logo.png" />
            </div>
          </div>
          <div class="title">注册</div>
          <div class="loginForm">
            <van-field v-model="code" clearable input-align="center" class="input" placeholder="请输入邀请码" />
            <van-field v-model="username" clearable input-align="center" class="input" placeholder="请输入账户" />
            <van-field v-model="password" :type="passwordType" input-align="center" class="input" placeholder="请输入密码">
              <template slot="right-icon">
                <van-icon :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'" @click="switchPasswordType" />
              </template>
            </van-field>
            <van-field v-model="password2" :type="passwordType2" input-align="center" class="input" placeholder="请确认密码">
              <template slot="right-icon">
                <van-icon :name="passwordType2 === 'password' ? 'closed-eye' : 'eye-o'" @click="switchPasswordType2" />
              </template>
            </van-field>
            <van-field v-model="usercode" clearable input-align="center" class="input" placeholder="请输入验证码">
              <template slot="right-icon">
                <van-image class="vcode-img" :src="vcode.img" @click="getVCode()" />
              </template>
            </van-field>

            <van-button class="login-btn" type="primary" size="small" @click="doRegister()">注册</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      usercode: '',
      vcode: {},
      checked: true,
      username: '',
      code: '',
      password2: '',
      passwordType2: 'password',
      password: this.inputValue,
      passwordType: 'password'
    }
  },
  methods: {
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    getVCode() {
      this.$http({
        method: 'get',
        url: 'vcode'
      })
        .then((res) => {
          console.log(res)
          this.vcode = { ovcode: res.data.code, img: res.data.img }
          console.log(this.vcode.img)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    switchPasswordType2() {
      this.passwordType2 = this.passwordType2 === 'password' ? 'text' : 'password'
    },
    back() {
      return window.history.back()
    },
    doRegister() {
      if (this.username === '' || this.username === null || this.username === undefined) {
        this.$toast.fail('请输入用户名！')
        return false
      }
      if (this.password === '' || this.password === null || this.password === undefined) {
        this.$toast.fail('请输入密码！')
        return false
      }
      if (this.password2 === '' || this.password2 === null || this.password2 === undefined) {
        this.$toast.fail('请输入确认密码！')
        return false
      }
      if (this.password2 != this.password) {
        this.$toast.fail('2次输入的密码不一致！')
        return false
      }
      if (this.code === '' || this.code === null || this.code === undefined) {
        this.$toast.fail('请输入邀请码！')
        return false
      }
      if (this.usercode === '' || this.usercode === null || this.usercode === undefined) {
        this.$toast.fail('请输入验证码！')
        return false
      }

      if (this.usercode != this.vcode.ovcode) {
        this.$toast.fail('验证码错误！')
        return false
      }

      this.$http({
        method: 'post',
        data: {
          username: this.username,
          password: this.password,
          vcode: this.usercode,
          code: this.code
        },
        url: 'member_register'
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg)
          localStorage.setItem('token', res.data)
          this.$router.push('Mine')
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  },
  created() {
    if (localStorage.getItem('token')) {
      return window.history.back()
    }
    this.getVCode()
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';

.register {
  height: 100%;
}
.bg-container .bg-wrapper .register .nav-bar {
  background: 0 0;
}
::v-deep ::placeholder {
  color: #777777; /* 另一个输入框的提示文字颜色 */
}
.bg-container {
  //url(../../static/img_m/login-bg.74269e6.png) no-repeat 50%/100% 100%,linear-gradient(-45deg,#7e5678,#e6c3a1)
  background: url(/img/login/login-bg.png) no-repeat 50%/100% 100%, linear-gradient(-45deg, #7e5678, #e6c3a1);
  background-size: 100% auto;
}

.register .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container {
  margin: 0 auto;
  width: 55%;
}
.logo-container .logo-wrapper {
  position: relative;
  padding-bottom: 50%;
}
.logo-container .logo-wrapper .logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.register .wrapper .title {
  line-height: 100px;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}
.register .wrapper .loginForm {
  padding: 30px;
}
.register .wrapper .loginForm .input {
  padding: 10px 10px;
  margin-top: 20px;
  border-radius: 50px;
  text-align: center;
  line-height: 70px;
  font-size: 30px;
  color: #4e4e4e;
}
::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.register .wrapper .loginForm .reset-text {
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.register .wrapper .loginForm .reset-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.register .wrapper .loginForm .register-text {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .register-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}
.register .wrapper .loginForm .login-btn {
  margin-top: 50px;
  width: 100%;
  height: 90px;
  border-radius: 50px;
  color: #fff;

  background: linear-gradient(270deg, #ff678e, #f6ce6b);
  font-size: 35px;
  font-weight: 500;
  border: none;
}
.register .wrapper .loginForm .agreement {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .agreement .agreement-text {
  margin-left: 10px;
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  line-height: 30px;
}
::v-deep .agreement .van-icon {
  font-size: 30px;
}
::v-deep .agreement .van-checkbox__icon {
  font-size: 38px;
}
::v-deep .agreement .van-checkbox__icon--checked .van-icon {
  color: #fff;
  border-color: rgb(126, 86, 120);
  background-color: rgb(126, 86, 120);
}
.vcode-img {
  position: absolute;
  top: 6%;
  height: 88%;
  right: 0;
}
</style>
